<template>
  <div class="Iframe">
    <el-button type="primary" class="fh" @click="fh">返回</el-button>
    <iframe class="znr" ref="prints" :src="src"></iframe>
  </div>
</template>

<script>
import isShow from "@/store/moddules/isShow";
import pf from '@/publicFn/baseFn'



export default {
  name: "ifame2",
  data() {
    return {
      src: '',
      type: 0
    }
  },
  watch: {
    '$route.query': function (n, o) {
      this.init()
    }
  },
  created() {
    this.init()
    window.addEventListener( "message", this.tz)
  },

  methods: {


    init() {
      this.$store.commit('isShow/upBottom', false)
      let type = this.$route.query.type
      let csid = this.$store.state.physicianVisits.xzConsulting,
          mdid = this.$store.state.bases.shop.ShopId,
          custid = this.$store.state.users.CsUser.CustomerId,
          exids = this.$store.state.users.employees?this.$store.state.users.employees.ExpertId:'',
          companyid = this.$store.state.users.user.Company.CompanyId,
          uids = this.$store.state.users.user.UserId
      let url = ''
      switch (Number(type)) {
        case 0: //充值
          url = this._getUrl('ShopRecharge')
          url = url.replace('{UserName}', '')
          url = url.replace('{ShopId}', mdid)
          this.src = url
          break
        case 1://药品处方
          url = this._getUrl('Page_DrugCHPPrint')
          url = url.replace('{ChooseId}', csid)
          url = url.replace('{ExpertId}', exids)
          this.src = url
          // console.log(url)
          break
      }
    },

    fh() {
      this.$router.push({path: '/'})
    },
  }
}
</script>

<style scoped lang="scss">
.Iframe {
  width: 100vw;
  height: 86vh;
  overflow: auto;
  -ms-overflow-style: none;
}
.Iframe::-webkit-scrollbar { width: 0 !important }
.znr {
  width: 100%;
  height: 100%;
}
.fh {
  position: fixed;
  top: 330px;
  left: 0;
}
.dy {top: 38vh;left: 0; margin: 0;}
</style>
